<template>
    <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
            <vx-card title="User Profile" class="mb-base" style="min-height: 100%">
                <div class="vx-row mr-4">
                    <div class="vx-col w-2/3" style="display: flex">
                        <div class="w-1/1 w-full ml-3">
                            <div class="vx-row mb-3 mr-4">
                                <div class="font-semibold vx-col sm:w-2/5 w-full">
                                    <span>Name</span>
                                </div>
                                <div class="vx-col sm:w-3/5 w-full">
                                    <span>{{ data.name }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-3 mr-4">
                                <div class="font-semibold vx-col sm:w-2/5 w-full">
                                    <span>ID Number</span>
                                </div>
                                <div class="vx-col sm:w-3/5 w-full">
                                    <span>{{ data.id_number }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-3 mr-4">
                                <div class="font-semibold vx-col sm:w-2/5 w-full">
                                    <span>Work ID Number</span>
                                </div>
                                <div class="vx-col sm:w-3/5 w-full">
                                    <span>{{ data.work_id_number }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-3 mr-4">
                                <div class="font-semibold vx-col sm:w-2/5 w-full">
                                    <span>Email</span>
                                </div>
                                <div class="vx-col sm:w-3/5 w-full">
                                    <span>{{ data.email }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-3 mr-4">
                                <div class="font-semibold vx-col sm:w-2/5 w-full">
                                    <span>Mobile</span>
                                </div>
                                <div class="vx-col sm:w-3/5 w-full">
                                    <span>{{ data.mobile }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-3 mr-4">
                                <div class="font-semibold vx-col sm:w-2/5 w-full">
                                    <span>Address</span>
                                </div>
                                <div class="vx-col sm:w-3/5 w-full">
                                    <span>{{ data.address }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-3 mr-4">
                                <div class="font-semibold vx-col sm:w-2/5 w-full">
                                    <span>Country</span>
                                </div>
                                <div class="vx-col sm:w-3/5 w-full">
                                    <span>{{ data.country }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-3 mr-4">
                                <div class="font-semibold vx-col sm:w-2/5 w-full">
                                    <span>Province</span>
                                </div>
                                <div class="vx-col sm:w-3/5 w-full">
                                    <span>{{ data.province }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-3 mr-4">
                                <div class="font-semibold vx-col sm:w-2/5 w-full">
                                    <span>City</span>
                                </div>
                                <div class="vx-col sm:w-3/5 w-full">
                                    <span>{{ data.city }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-3 mr-4">
                                <div class="font-semibold vx-col sm:w-2/5 w-full">
                                    <span>District</span>
                                </div>
                                <div class="vx-col sm:w-3/5 w-full">
                                    <span>{{ data.district }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-3 mr-4">
                                <div class="font-semibold vx-col sm:w-2/5 w-full">
                                    <span>Sub District</span>
                                </div>
                                <div class="vx-col sm:w-3/5 w-full">
                                    <span>{{ data.sub_district }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="vx-col w-1/3">
                        <div class="w-1/1 w-full">
                            <div class="mb-4">
                                <img style="max-height: 130px; max-width: auto;" :src="data.avatar" class="rounded" />
                            </div>
                        </div>
                    </div>
                    <div class="vx-col w-full flex mt-5">
                        <!-- <vs-button @click="handleEdit()" icon-pack="feather" icon="icon-edit" class="mr-4">Edit</vs-button> -->
                        <vs-button @click="handleChangePassword()" icon-pack="feather" icon="icon-edit" class="mr-4">Change Password</vs-button>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>
<script>
export default {
    created() {
        this.getData()
    },
    data() {
        return this.initialState();
    },
    methods: {
        initialState() {
            return {
                data: {
                    avatar: "",
                    name: "",
                    id_number: "",
                    work_id_number: "",
                },
            };
        },
        handleEdit() {

            this.$router.push("/profile/edit");
        },
        handleChangePassword() {
            this.$router.push("/profile/change-password");
        },
        getData(){
            this.$vs.loading();
            this.$http
            .get("api/wms/v1/setting/users/personal").then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.data = resp.data
                    this.data.avatar = resp.data.avatar;

                    console.log("this.data", this.data)
                    // this.responseData.work_group_name = resp.data.work_group_name;
                    // this.responseData.role = resp.data.role;
                    // this.roleGroup = resp.data.role_group;
                    // this.responseData.work_id_number = resp.data.work_id_number;
                    // this.responseData.work_group_id = resp.data.work_group_id;
                    // this.responseData.id_number = resp.data.id_number;
                    // this.responseData.name = resp.data.name;
                    // this.responseData.address = resp.data.address;
                    // this.responseData.mobile = resp.data.mobile;
                    // this.responseData.email = resp.data.email;
                    // this.responseData.territory_area = resp.data.territory_area;
                    // this.responseData.territory = resp.data.territory;
                    // this.territoryArea.selected = resp.data.territory_area_id;
                    // this.territory.selected = resp.data.territory_id;
                    // this.responseData.avatar_show = "https://cdn.dbms.co.id/uploads/user/"+resp.data.avatar;
                    // this.responseData.avatar = resp.data.avatar;
                }
            });
        },
    }
}
</script>
